@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Syne:wght@400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.px-full{
  @apply px-24
}

/* 
font-family: 'Open Sans', sans-serif;
font-family: 'Syne', sans-serif; 
*/

h1, h2, h3{
  font-family: 'Syne', sans-serif;
}

h4, h5, h6, p{
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-weight: bold;
  line-height: 1.1em;
  font-size: 3rem;
}
h2 {
  font-weight: bold;
  line-height: 1.1em;
  font-size: 2.5rem;
}
h3 {
  font-weight: normal;
  line-height: 1.1em;
  font-size: 2rem;
}
h4 {
  font-weight: normal;
  line-height: 1.1em;
  font-size:1.5rem;
}
h5 {
  font-weight: normal;
  line-height: 1.1em;
  font-size: 1.25rem;
}
h6 {
  font-weight: normal;
  line-height: 1.1em;
  font-size: 1.125rem;
}
p  {
  font-weight: normal;
  line-height: 1.5em;
  font-size: 1rem;
}

*{
  box-sizing: border-box;
  font-weight: 100;
}